import identity from 'lodash/identity';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import remove from 'lodash/remove';
import find from 'lodash/find';
import range from 'lodash/range';
import map from 'lodash/map';
import pickBy from 'lodash/pickBy';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import {
  getNbaHotLead,
  getNextBestActionScoreByProspect
} from 'LegacyAngularApp/legacy-angular-app/services/demandXService';
import { APP_CONFIG } from 'APP_CONFIG';

(function (angular) {
  const app = angular.module('knockApp');

  app.directive('prospectDetails', function () {
    return {
      restrict: 'E',
      templateUrl: '/angular/views/prospects/prospect-details.html',
      controller: 'ProspectsDetailsController',
      scope: {
        prospect: '=',
        listing: '=',
        stream: '=',
        connectedProfiles: '='
      }
    };
  });

  const ProspectsDetailsController = function (
    $q,
    $rootScope,
    $scope,
    $mdToast,
    appDataService,
    quotesService,
    userInteractionsService,
    teamApi,
    prospectsApi,
    propertyApi,
    relayApi,
    unitApi,
    OBSOLETE_rentMatrixService,
    alternativeContactModalFactory,
    prospectLossReasonsService,
    visitsService,
    prospectHistoryService,
    conversationsService,
    streamCarouselService,
    managerCalendarEventsService,
    newProspectService,
    $moment,
    $mdDialog,
    contactTypes,
    unsubscribeHelper,
    userService,
    profileService
  ) {
    const self = this;

    // Handle carousel swap:
    $scope.$watch('prospect.id', function () {
      self.initializeProspectData();
    });

    $scope.$watch('prospect.property_id', function () {
      self.initializePropertyData();
    });

    $scope.$watch('prospect.profile.target_move_date', function () {
      self._fixTargetMoveDate();
      self._getCurrentLeasingTeamCustomFields();
    });

    $scope.$watch('prospect.leased_date', function () {
      self._fixLeasedDate();
    });

    $scope.$watch('prospect.status', function (newValue, oldValue) {
      if (newValue && oldValue && newValue !== oldValue) {
        self._setIsProspectPreferencesInputsDisabled();
        self._setisProspectInputsDisabled();

        if (!$scope.data.prospectGuestCardLinkType === 'OneSite') {
          self._setProspectGuestCardLinkType();
        }
      }
      self._getCurrentLeasingTeamCustomFields();
    });

    $scope.getScopedLeasingTeam = function () {
      const scopedLeasingTeamId = userService.getScopedLeasingTeamId();
      return scopedLeasingTeamId;
    };

    $scope.isConnectedProfilesEnabled =
      $rootScope.appPreferences.company.enable_connected_profiles;

    // Is the Refer (previously Nearby Communities) section enabled?
    $scope.isReferralsEnabled =
      $rootScope.appPreferences.company.enable_property_referrals;

    $scope.isCrossSellEnabled =
      $scope.isConnectedProfilesEnabled && $scope.isReferralsEnabled;

    // Maintain prospectReferralPreferences (previously nearbyCommunitiesPreferences)
    $scope.prospectReferralPreferences = {};
    const setProspectReferralPreferences = debounce((preferences) => {
      $scope.$applyAsync(() => {
        $scope.prospectReferralPreferences = preferences;
      });
    }, 500);
    $scope.$watch(
      'prospect',
      (newProspect) => {
        let preferences = {};

        // If the prospect is null, don't bother populating preferences
        if (!newProspect) {
          return;
        }

        if (newProspect.preferences.bedrooms) {
          preferences.bedrooms = newProspect.preferences.bedrooms;
        }

        if (newProspect.preferences.min_price) {
          preferences.minPrice = newProspect.preferences.min_price;
        }

        if (newProspect.preferences.max_price) {
          preferences.maxPrice = newProspect.preferences.max_price;
        }

        if (newProspect.profile.pets) {
          preferences.allowCats = newProspect.profile.pets.cats;
          preferences.allowSmallDogs = newProspect.profile.pets.small_dogs;
          preferences.allowLargeDogs = newProspect.profile.pets.large_dogs;
        }

        if (newProspect.custom_fields) {
          preferences.custom_fields = newProspect.custom_fields;
        }
        if (newProspect.stored_custom_fields) {
          preferences.stored_custom_fields = newProspect.stored_custom_fields;
        }

        // If the preferences have changed, update it!
        if (!isEqual(preferences, $scope.prospectReferralPreferences)) {
          setProspectReferralPreferences(preferences);
        }
      },
      true
    );

    // These are readonly (modifying them will do nothing) values
    // that give the initialized states of the angular ui bootstrap
    // accordion components.
    // https://angular-ui.github.io/bootstrap/versioned-docs/0.13.0/
    $scope.accordionIsOpen = {
      profile: true,
      details: false,
      events: false,
      preferences: false,
      secondaryContacts: false,
      pets: false,
      currentAddress: false,
      extra: false,
      nearbyCommunities: false
    };

    $scope.mergeOptionsRaw = null;

    $scope.data = {
      isEditing: false,
      isLoaded: false,
      isDeletingProspect: false,
      isMobileToursEnabled:
        $rootScope.appPreferences.company.enable_mobile_tours,
      isSavingProspect: false,
      prospectSaved: false,
      prospectSaveError: false,
      teamMembers: null,
      contactTypes: contactTypes.getAll(),
      properties: {
        singleFamily: []
      },
      property: null,
      hasRentMatrix: false,
      assignedManagerId: $scope.prospect.assigned_manager_id,
      quotes: [],
      alternativeContacts: [],
      isDeletingAlternativeContactId: null,
      exportStatus: newProspectService.getProspectExportStatus(
        $scope.prospect.id
      ),
      target_move_date: null,
      mergeOptions: null,
      showMergeModal: false,
      showDuplicateAlert: true,
      mergeDetectionEnabled:
        $rootScope.appPreferences.company.enable_prospect_merge,
      newUnitsFeature: $rootScope.featureFlags.NEW_UNITS || false,
      isConnectedProfilesDialogActive: false,
      connectedProfiles: [],
      isProspectPreferencesInputsDisabled: false,
      isEmailDomainBlocked: false,
      hasEmailOptOut: false,
      prospectGuestCardLinkType: null,
      isProspectInputsDisabled: {
        value: false,
        message: ''
      },
      isHotLead: false,
      initialAiEmailEnabled: $scope.prospect.ai_email.enabled,
      aiEmailTogglable: $scope.prospect.ai_email.togglable,
      custom_fields: $scope.prospect.stored_custom_fields
    };

    $scope.isYardi =
      $scope.prospect.integrations &&
      $scope.prospect.integrations.some(
        (integration) => integration.vendor === 'yardi'
      );

    $scope.isAppfolio =
      $scope.prospect.integrations &&
      $scope.prospect.integrations.some(
        (integration) => integration.vendor === 'appfolio'
      );

    $scope.isRealPage =
      $scope.prospect.integrations &&
      $scope.prospect.integrations.some(
        (integration) => integration.vendor === 'realpage'
      );

    self._setIsProspectPreferencesInputsDisabled = function () {
      const disabledStatus = ['in-review', 'applied-leased', 'applied-lost'];
      if ($scope.prospect && $scope.prospect.status) {
        $scope.data.isDesiredLeaseTermDisabled = disabledStatus.some(
          (status) => status === $scope.prospect.status
        );
        $scope.data.isProspectPreferencesInputsDisabled =
          $scope.data.isDesiredLeaseTermDisabled && $scope.isYardi;
      }
    };

    $scope.isDemandXEnabled = $rootScope.appPreferences.company.enable_demand_x;

    $scope.isDemandXDemoEnabled =
      $rootScope.featureFlags.DEMAND_X_DEMO &&
      $rootScope.appPreferences.company.enable_demand_x;

    $scope.isCoApplicantsEnabled = false;

    self._getNBA = async function () {
      if (!$scope.isDemandXEnabled && !$scope.isDemandXDemoEnabled) {
        return;
      }

      if (
        $scope.isDemandXDemoEnabled &&
        $scope.prospect.profile.first_name.toLowerCase().startsWith('k')
      ) {
        $scope.data.isHotLead = true;
        return;
      }

      try {
        const nextBestActionScore = await getNextBestActionScoreByProspect({
          pmc_id: appDataService.data.currentCompany.id,
          prospect_id: $scope.prospect.id,
          property_id: $scope.prospect.property_id
        });

        $scope.data.isHotLead = getNbaHotLead(
          nextBestActionScore,
          $rootScope.featureFlags.DEMAND_X_PRIORITY
        );
      } catch (error) {
        console.error('Error getting leadScore:', error.message);

        $scope.data.isHotLead = false;
      }
    };

    self._setProspectGuestCardLinkType = function () {
      if (
        !$scope.prospect.integrations ||
        $scope.prospect.integrations.length === 0
      ) {
        return;
      }

      const isOneSiteGuestCardLinkEnabled =
        $rootScope.featureFlags.ONESITE_APPLICANT_GUESTCARD_LINK;

      if (isOneSiteGuestCardLinkEnabled && $scope.isRealPage) {
        profileService.getProfile(true).success(function (response) {
          const profile = response.profile;
          if (profile.is_migrated) {
            $scope.data.prospectGuestCardLinkType = 'OneSite';
            return;
          }
        });
      }

      const isOnSiteLeasingProvider =
        $scope.data.property?.data.leasing?.provider === 'On-Site';
      const isOnSiteLightVersionEnabled =
        $rootScope.featureFlags.OSM_LIGHT_GUESTCARD_LINK;

      if (
        $scope.isYardi &&
        (isOnSiteLeasingProvider || isOnSiteLightVersionEnabled) &&
        $scope.prospect &&
        $scope.prospect.status
      ) {
        const validOnSiteProspectStatuses = [
          'in-review',
          'applied-lost',
          'applied-leased',
          'application-in-progress',
          'applied-pending-signature'
        ];

        const isProspectStatusValid = validOnSiteProspectStatuses.some(
          (status) => status === $scope.prospect.status
        );

        if (isProspectStatusValid) {
          $scope.data.prospectGuestCardLinkType = isOnSiteLightVersionEnabled
            ? 'OSM-light'
            : 'OSM-full';
          return;
        }
      }

      $scope.data.prospectGuestCardLinkType = null;
    };

    self._setIsCoApplicantsEnabled = function () {
      const isLeasingProviderOnSite =
        $scope.data.property?.data.leasing?.provider === 'On-Site';
      $scope.isCoApplicantsEnabled =
        $rootScope.featureFlags.CO_APPLICANTS && isLeasingProviderOnSite;
    };

    self._setisProspectInputsDisabled = function () {
      const applicantStatuses = [
        'in-review',
        'application-in-progress',
        'applied-pending-signature',
        'applied-leased',
        'applied-lost'
      ];

      const hasIntegrations =
        $scope.prospect.integrations && $scope.prospect.integrations.length > 0;

      if (
        hasIntegrations &&
        $scope.prospect &&
        $scope.prospect.status &&
        applicantStatuses.some((status) => status === $scope.prospect.status)
      ) {
        $scope.data.isProspectInputsDisabled = {
          value: true,
          message: 'Go to your PMS to update this field.'
        };
      } else {
        $scope.data.isProspectInputsDisabled = {
          value: false,
          message: ''
        };
      }
    };

    $scope.toggleMergeModal = () => {
      $scope.$apply(() => {
        $scope.data.showMergeModal = !$scope.data.showMergeModal;
      });
    };

    $scope.setIsConnectedProfilesDialogActive = (isActive) => {
      $scope.$apply(() => {
        $scope.data.isConnectedProfilesDialogActive = isActive;
      });
    };

    $scope.toggleDuplicateAlert = () => {
      $scope.$apply(() => {
        $scope.data.showDuplicateAlert = !$scope.data.showDuplicateAlert;
      });
    };

    $scope.getDuplicates = async () => {
      const {
        data: { duplicate_prospects }
      } = await prospectsApi.getProspectDuplicates($scope.prospect.id);
      $scope.$apply(() => {
        $scope.mergeOptionsRaw = duplicate_prospects;
        $scope.data.mergeOptions = duplicate_prospects.map((p) => ({
          id: p.id,
          firstName: p.profile.first_name,
          lastName: p.profile.last_name,
          email: p.profile.email,
          phoneNumber: p.profile.formatted_phone_number,
          source: p.profile.source,
          streamId: p.stream_id
        }));
      });
    };

    $scope.getMergeDecisions = async (master_id, secondary_id) => {
      const { data } = await prospectsApi.getMergeDecisions(
        master_id,
        secondary_id
      );
      const masterId = data.decisions.master.id;

      let master;
      if (masterId === $scope.prospect.id) {
        master = $scope.prospect;
      } else {
        master = $scope.mergeOptionsRaw.filter((p) => masterId === p.id)[0];
      }

      $scope.masterProspect = master;

      return data;
    };

    $scope.setMergeDecisions = function (masterId, secondaryId, defaults) {
      return prospectsApi.setMergeDecisions(masterId, secondaryId, defaults);
    };

    $scope.mergeSuccess = () => {
      if ($scope.masterProspect) {
        window.location.href = `${APP_CONFIG.REACT_APP_WEBFRONT_BASE_URL}/conversation/${$scope.masterProspect.stream_id}`;
      }
    };

    $scope.bedroomTypes = ['Studio', '1 br', '2 br', '3+ br'];
    $scope.possibleNumberOfOccupants = [
      { text: '1', value: 1 },
      { text: '2', value: 2 },
      { text: '3', value: 3 },
      { text: '4', value: 4 },
      { text: '5', value: 5 },
      { text: '6', value: 6 },
      { text: '7', value: 7 },
      { text: '8', value: 8 },
      { text: '9', value: 9 },
      { text: '10+', value: 10 }
    ];
    $scope.possibleLeaseTerms = map(range(1, 19, 1), function (value) {
      return {
        value: value,
        label: '' + value + (value === 1 ? ' month' : ' months')
      };
    });

    const prospectUpdatedDestroy = $rootScope.$on(
      prospectHistoryService.events.prospectUpdated,
      function (event, prospect) {
        if ($scope.prospect.id === prospect.id) {
          prospectsApi
            .getProspect($scope.prospect.id)
            .success(function (response) {
              $scope.prospect = response.prospect;
              $scope.data.assignedManagerId =
                $scope.prospect.assigned_manager_id;
            });
        }
      }
    );

    const appointmentsTransferredDestroy = $rootScope.$on(
      userInteractionsService.events.prospectAppointmentsTransferred,
      function () {
        self._refreshEvents();
      }
    );

    const requestConfirmedDestroy = $rootScope.$on(
      managerCalendarEventsService.eventTypes.pendingRequest.wasAccepted,
      function () {
        self._refreshEvents();
      }
    );

    const smsConsentChangedDestroy = $rootScope.$on(
      conversationsService.events.smsConsentChanged,
      function (event, payload) {
        if (
          payload.type === 'prospect' &&
          $scope.prospect &&
          payload.id === $scope.prospect.id
        ) {
          if (!$scope.prospect.sms_consent) {
            $scope.prospect.sms_consent = {};
          }

          $scope.prospect.sms_consent.status = payload.status;
        }
      }
    );

    const quoteAddedDestroy = $rootScope.$on(
      quotesService.events.quoteAdded,
      function (event, quote) {
        $scope.data.quotes.push(quote);
      }
    );

    const visitAddedDestroy = $rootScope.$on(
      visitsService.events.visitCreated,
      function (event, visit) {
        if (!visit.appointment_id) {
          $scope.prospect.events.push(visit);
        } else {
          self._refreshEvents();
        }
      }
    );

    const prospectExportUpdatedDestroy = $rootScope.$on(
      newProspectService.events.prospectExportUpdated,
      function (event, data) {
        if (data.prospectId === $scope.prospect.id) {
          $scope.data.exportStatus = newProspectService.getProspectExportStatus(
            $scope.prospect.id
          );

          if (data.status === 'completed') {
            prospectsApi
              .getProspect($scope.prospect.id)
              .then(function (response) {
                $scope.prospect.integrations =
                  response.data.prospect.integrations;
              });
          }
        }
      }
    );

    const prospectSyncCompletedDestroy = $rootScope.$on(
      newProspectService.events.prospectSyncComplete,
      function (event, prospectId) {
        if (prospectId === $scope.prospect.id) {
          prospectsApi
            .getProspect($scope.prospect.id)
            .then(function (response) {
              $scope.prospect = response.data.prospect;
            });
        }
      }
    );

    self._refreshEvents = function () {
      prospectsApi.getProspect($scope.prospect.id).then(function (response) {
        $scope.prospect.events = response.data.prospect.events;
      });
    };

    $scope.$on('$destroy', function () {
      prospectUpdatedDestroy();
      appointmentsTransferredDestroy();
      smsConsentChangedDestroy();
      quoteAddedDestroy();
      visitAddedDestroy();
      requestConfirmedDestroy();
      prospectExportUpdatedDestroy();
      prospectSyncCompletedDestroy();
    });

    $scope.$watch(
      'prospect.profile.pets',
      function (newRenterPets, oldRenterPets) {
        if (!newRenterPets || !oldRenterPets) {
          return;
        }

        if (newRenterPets.none !== oldRenterPets.none) {
          return;
        }

        if (
          newRenterPets['cats'] ||
          newRenterPets['small_dogs'] ||
          newRenterPets['large_dogs']
        )
          $scope.prospect.profile.pets.none = false;
      },
      true
    );

    $scope.clearSelectedPets = function () {
      $scope.prospect.profile.pets.cats = false;
      $scope.prospect.profile.pets.small_dogs = false;
      $scope.prospect.profile.pets.large_dogs = false;
    };

    $scope.tryDeleteProspect = function () {
      const confirm = $mdDialog
        .confirm()
        .title('Delete prospect')
        .content('Are you sure you wish to delete this prospect?')
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm).then(function () {
        self._deleteProspect();
      });
    };

    self._reportProspectExportFailure = function (failure) {
      return prospectsApi
        .reportProspectExportFailure(failure)
        .success(function () {
          $mdToast.showSimple(
            'Thanks, the error has been reported to Knock. We will get back to you soon.'
          );
        });
    };

    $scope.openLossReasonsModal = function () {
      prospectLossReasonsService
        .openProspectLossReasonsModal($scope.prospect)
        .then(function () {
          const ignoreToast = true;
          $scope.saveProspect(ignoreToast);
        })
        .then(self._initialize);
    };

    $scope.setProspectStatus = function () {
      self._calcIsProspectLost();

      if ($scope.isProspectLost) {
        $scope.openLossReasonsModal();
      }

      if ($scope.prospect.status === 'applied-leased') {
        $scope.prospect.leased_date = $moment().format();
      }
    };

    self._deleteProspect = function () {
      $scope.data.isDeletingProspect = true;

      prospectHistoryService
        .deleteProspect($scope.prospect)
        .success(self._deleteProspectSuccess);
    };

    self._deleteProspectSuccess = function () {
      $scope.data.isDeletingProspect = false;
    };

    $scope.reassignStreamPhoneNumber = function () {
      self._postReassignStreamPhoneNumber();
    };

    $scope.saveProspect = function (ignoreToast) {
      if (
        $scope.prospect.status === 'applied-leased' &&
        !$scope.prospect.leased_date
      ) {
        $mdToast.showSimple(
          'Lease start date cannot be empty if status is Leased.'
        );
        return;
      }

      $scope.data.isSavingProspect = true;

      $scope.prospect.profile.address = $scope.prospect.profile.address || {};

      if ($scope.prospect.profile.target_move_date_null) {
        $scope.prospect.profile.target_move_date = $moment(
          $scope.prospect.profile.target_move_date
        ).add(12, 'hours');
      }
      const payload = {
        id: $scope.prospect.id,
        profile: {
          first_name: $scope.prospect.profile.first_name,
          last_name: $scope.prospect.profile.last_name,
          email: $scope.prospect.profile.email,
          phone: $scope.prospect.profile.phone,
          target_move_date: $scope.prospect.profile.target_move_date,
          pets: $scope.prospect.profile.pets,
          address: {
            thoroughfare: $scope.prospect.profile.address.thoroughfare,
            premise: $scope.prospect.profile.address.premise,
            locality: $scope.prospect.profile.address.locality,
            administrative_area:
              $scope.prospect.profile.address.administrative_area,
            postal_code: $scope.prospect.profile.address.postal_code,
            country: $scope.prospect.profile.address.country
          },
          is_winback_enabled:
            $scope.prospect.profile.is_winback_enabled !== false
        },
        ai_email_enabled: $scope.prospect.ai_email.enabled !== false,
        property_id: $scope.prospect.property_id,
        source: $scope.prospect.source,
        last_contacted_time: $scope.prospect.last_contacted_time,
        first_contact_type: $scope.prospect.first_contact_type,
        preferences: {
          bedrooms: $scope.prospect.preferences.bedrooms,
          min_price: $scope.prospect.preferences.min_price,
          max_price: $scope.prospect.preferences.max_price,
          must_have: $scope.prospect.preferences.must_have,
          number_of_occupants: $scope.prospect.preferences.number_of_occupants,
          preferred_lease_term_months:
            $scope.prospect.preferences.preferred_lease_term_months,
          // TODO: remove references to legacy property floorplan field
          preferred_property_floorplan_id:
            $scope.prospect.preferences.preferred_property_floorplan_id,
          preferred_layout_id: $scope.prospect.preferences.preferred_layout_id,
          preferred_property_unit_id:
            $scope.prospect.preferences.preferred_property_unit_id,
          preferred_unit_id: $scope.prospect.preferences.preferred_unit_id
        },
        leased_date: $scope.prospect.leased_date,
        is_waitlist: $scope.prospect.is_waitlist,
        custom_fields: $scope.prospect.stored_custom_fields,
        tours_notes: $scope.prospect.tours_notes
      };

      prospectHistoryService
        .updateProspect(payload)
        .success(function (response) {
          self._sendInternalNote(response.prospect.ai_email_enabled);
          self._saveProspectFinished(ignoreToast);
        })
        .error(self._saveProspectError)
        .finally(function () {
          $scope.data.isSavingProspect = false;
        });
    };

    $scope.restoreProspect = function () {
      $scope.data.isRestoringProspect = true;

      prospectHistoryService
        .restoreProspect($scope.prospect.id)
        .success(self._restoreProspectFinished)
        .error(self._restoreProspectError)
        .finally(function () {
          $scope.data.isRestoringProspect = false;
        });
    };

    $scope.getManagerInfo = function (managerId) {
      const teamMember = find($scope.teamMembers, function (member) {
        return member.Manager.id === managerId;
      });

      return teamMember ? teamMember.ManagerInfo : null;
    };

    $scope.enterTheMatrix = function () {
      OBSOLETE_rentMatrixService.openMatrixModal(
        $scope.prospect.property_id,
        null,
        null,
        $scope.prospect.profile.target_move_date
      );
    };

    $scope.addAlternativeContact = function () {
      if (self.isLeasingProviderOnSiteOsm()) {
        const alert = $mdDialog.alert({
          title: 'Add secondary contact in On-Site',
          content:
            'Unable to add secondary contacts in Knock CRM as this property utilizes On-Site as its primary leasing provider. Please use On-Site to add secondary contacts.',
          ok: 'Close'
        });
        $mdDialog.show(alert);
        return;
      }

      return alternativeContactModalFactory
        .openModal($scope.prospect, null)
        .then(function (alternativeContact) {
          $scope.data.alternativeContacts.push(
            self._alternativeContactSnakeToCamel(alternativeContact)
          );
        });
    };

    $scope.updateAlternativeContact = function (alternativeContact, index) {
      if (self.isLeasingProviderOnSiteOsm()) {
        const alert = $mdDialog.alert({
          title: 'Edit secondary contact in On-Site',
          content:
            'Unable to edit secondary contacts in Knock CRM as this property utilizes On-Site as its primary leasing provider. Please use On-Site to edit secondary contacts.',
          ok: 'Close'
        });
        $mdDialog.show(alert);
        return;
      }

      return alternativeContactModalFactory
        .openModal($scope.prospect, alternativeContact)
        .then(function (updatedAlternativeContact) {
          $scope.data.alternativeContacts[index] =
            self._alternativeContactSnakeToCamel(updatedAlternativeContact);
        });
    };

    $scope.tryDeleteAlternativeContact = function (alternativeContact) {
      if (self.isLeasingProviderOnSiteOsm()) {
        const alert = $mdDialog.alert({
          title: 'Delete secondary contact in On-Site',
          content:
            'Unable to delete secondary contacts in Knock CRM as this property utilizes On-Site as its primary leasing provider. Please use On-Site to delete secondary contacts.',
          ok: 'Close'
        });
        $mdDialog.show(alert);
        return;
      }

      const confirmDeleteAlternativeContact = $mdDialog
        .confirm()
        .title('Confirm delete')
        .content(
          'Do you want to delete the secondary contact ' +
            alternativeContact.firstName +
            ' ' +
            alternativeContact.lastName +
            '?'
        )
        .ok('Yes')
        .cancel('No');

      $mdDialog.show(confirmDeleteAlternativeContact).then(function () {
        $scope.data.isDeletingAlternativeContactId = alternativeContact.id;
        prospectsApi
          .deleteProfileAlternativeContact(alternativeContact.id)
          .success(function () {
            remove($scope.data.alternativeContacts, {
              id: alternativeContact.id
            });

            $scope.data.isDeletingAlternativeContactId = null;
            $mdToast.showSimple('Secondary contact deleted!');
          })
          .error(function () {
            $scope.data.isDeletingAlternativeContactId = null;
            $mdToast.showSimple(
              'There was an error deleting the secondary contact.'
            );
          });
      });
    };

    $scope.refreshConversation = function () {
      $rootScope.$emit(conversationsService.events.refreshConversation);
    };

    $scope.checkEmailWarnings = function () {
      const email = $scope.prospect.profile.email;
      if (
        !$rootScope.emailDomainBlocklist ||
        !email ||
        email.lastIndexOf('@') < 0
      ) {
        $scope.data.isEmailDomainBlocked = false;
        return;
      }

      const domain = email.substring(email.lastIndexOf('@') + 1);
      $scope.data.isEmailDomainBlocked = $rootScope.emailDomainBlocklist.some(
        (pattern) => new RegExp(pattern).test(domain)
      );
    };

    $scope.downloadSelfiescanPDF = function () {
      prospectsApi
        .getProspectSelfieScanPDF($scope.prospect.id)
        .success((response) => {
          var fileURL = URL.createObjectURL(
            new Blob([response], { type: 'application/pdf' })
          );
          window.open(fileURL, '_blank', '');
        })
        .error(() => {
          $mdToast.showSimple('There was an error fetching the ID Scan PDF.');
        });
    };

    self._getHasEmailOptOut = function () {
      $scope.data.hasEmailOptOut = false;
      if ($scope.stream) {
        const unsubscribeStatus =
          unsubscribeHelper.getUnsubscribeStatusFromStream($scope.stream);
        if (unsubscribeStatus && unsubscribeStatus.opted_out) {
          $scope.data.hasEmailOptOut = true;
        }
      }
    };

    self._saveProspectError = function () {
      $mdToast.showSimple('Error saving prospect');
    };

    self._saveProspectFinished = function (ignoreToast) {
      if (!ignoreToast) {
        $mdToast.showSimple('Prospect saved!');
      }
      self._getPossibleDuplicates();
      $scope.data.isSavingProspect = false;

      $rootScope.$emit('prospectSaved', $scope.prospect.profile.email);
    };

    self._sendInternalNote = function (newAiEmailEnabledValue) {
      if (
        $scope.data.aiEmailTogglable &&
        $scope.data.initialAiEmailEnabled !== newAiEmailEnabledValue
      ) {
        userInteractionsService.addNewActivity(
          $scope.prospect,
          'note',
          newAiEmailEnabledValue
            ? 'AI Email switch has been turned on. AI Email responses enabled.'
            : 'AI Email switch has been turned off. AI Email responses disabled.',
          ''
        );
        $scope.data.initialAiEmailEnabled = newAiEmailEnabledValue;
      }
    };

    self._archiveProspectError = function () {
      $mdToast.showSimple('Error archiving prospect');
    };

    self._archiveProspectFinished = function () {
      streamCarouselService.onStreamArchived($scope.prospect.stream_id);
    };

    self._restoreProspectError = function () {
      $mdToast.showSimple('Error restoring prospect');
    };

    self._restoreProspectFinished = function () {
      $scope.prospect.is_deleted = false;
      $mdToast.showSimple('Prospect restored!');
    };

    self._initialize = function () {
      $scope.data.isLoaded = false;
      if ($scope.prospect.export_failures) {
        // Only display one failure per operation
        $scope.prospectExportFailures = values(
          keyBy($scope.prospect.export_failures, 'operation')
        );
      }

      if ($scope.prospect.profile.target_move_date === null) {
        $scope.prospect.profile.target_move_date_null = true;
      }

      self._calcIsProspectLost();
      self._setIsProspectPreferencesInputsDisabled();
      self._setProspectGuestCardLinkType();
      self._setisProspectInputsDisabled();

      const initPromises = [
        self._getProspectLossReasons(),
        self._getCurrentLeasingTeamCustomFields(),
        self._getNBA()
      ];

      $q.all(initPromises).finally(self._loadFinished);
    };

    self.initializePropertyData = function () {
      if ($scope.prospect.property_id) {
        $scope.data.property = appDataService.getProperty(
          $scope.prospect.property_id
        ).Property;

        self._getHasRentMatrix();

        if ($scope.data.newUnitsFeature) {
          self._getAvailableUnits();
        } else {
          // TODO: Legacy, remove as part of UnitService clean up
          self._getPropertyAvailabilities();
        }

        self._getPropertyFloorplans();
        self._setProspectGuestCardLinkType();
        self._setisProspectInputsDisabled();
        self._setIsCoApplicantsEnabled();
        self._getCurrentLeasingTeamCustomFields();
      }
    };

    self.initializeProspectData = function () {
      $scope.data.assignedManagerId = $scope.prospect.assigned_manager_id;
      $scope.data.isHotLead = false;
      const initPromises = [];
      initPromises.push(self._getQuotes());
      initPromises.push(self._getAlternativeContacts());
      initPromises.push(self._getPossibleDuplicates());
      initPromises.push(self._calcIsProspectLost());
      initPromises.push(self._getProspectLossReasons());
      initPromises.push(self._getNBA());
      initPromises.push(self._getCurrentLeasingTeamCustomFields());
      $scope.data.isLoaded = false;
      $q.all(initPromises).finally(self._loadFinished);
    };

    self._loadFinished = function () {
      $scope.data.isLoaded = true;
      $scope.data.encodedProspectId = window.btoa(
        $scope.prospect.id.toString()
      );
      $scope.checkEmailWarnings();

      self._getHasEmailOptOut();
      self._fixTargetMoveDate();
      self._fixLeasedDate();
      self._parseNumberOfOccupants();
      self._getCurrentLeasingTeamCustomFields();
    };

    self._fixTargetMoveDate = function () {
      if (
        $scope.prospect.profile.target_move_date &&
        typeof $scope.prospect.profile.target_move_date == 'string'
      ) {
        $scope.prospect.profile.target_move_date = $moment(
          $scope.prospect.profile.target_move_date + 'T12:00:00Z'
        );
      }
    };

    self._fixLeasedDate = function () {
      if (
        $scope.prospect.leased_date &&
        typeof $scope.prospect.leased_date == 'string'
      ) {
        $scope.prospect.leased_date = $moment(
          $scope.prospect.leased_date + 'T12:00:00Z'
        );
      }
    };

    self._parseNumberOfOccupants = function () {
      if ($scope.prospect.preferences.number_of_occupants) {
        const numberOfOccupants = parseInt(
          String($scope.prospect.preferences.number_of_occupants).replace(
            '+',
            ''
          )
        );
        $scope.prospect.preferences.number_of_occupants =
          numberOfOccupants >= 10 ? 10 : numberOfOccupants;
      }
    };

    self._calcIsProspectLost = function () {
      $scope.isProspectLost = prospectLossReasonsService.statusIsLost(
        $scope.prospect.status
      );
    };

    self._getProspectLossReasons = function () {
      return prospectLossReasonsService
        .getProspectLossReasons($scope.prospect.id)
        .then(function (result) {
          const lossReasonsMap = result.data.loss_reasons;
          $scope.lossReasons = Object.keys(pickBy(lossReasonsMap, identity));
        });
    };

    self.isLeasingProviderOnSiteOsm = function () {
      return $scope.data.property.data.leasing.provider === 'On-Site';
    };

    self._getProperty = function () {
      return propertyApi
        .getProperty($scope.prospect.property_id)
        .success(function (response) {
          $scope.data.property = response.property;
        });
    };

    self._getPropertyAvailabilities = function () {
      propertyApi
        .getPropertyUnitListings($scope.data.property.id)
        .success(function (response) {
          $scope.unitListings = response.unit_listings;
        });
    };
    self._getAvailableUnits = () => {
      // Communicate with the UnitService to return any units who are available now
      $scope.isLoadingUnits = true;
      const params = {
        propertyId: $scope.prospect.property_id,
        status: 'available'
      };

      unitApi
        .getUnits(params)
        .success((response) => {
          $scope.units = response.units;
          $scope.data.layouts = response.layouts;
        })
        .error(self._loadError)
        .finally(() => ($scope.isLoadingUnits = false));
    };

    self._getPropertyFloorplans = function () {
      $scope.isLoadingFloorplans = true;
      if (!$scope.data.newUnitsFeature) {
        // TODO: remove legacy property floorplan logic
        propertyApi
          .getPropertyFloorplans($scope.data.property.id)
          .success(function (response) {
            $scope.propertyFloorplans = response.floorplans;
          })
          .catch(() => $mdToast.showSimple('Error fetching floorplan data'))
          .finally(($scope.isLoadingFloorplans = false));
      }
    };

    self._getHasRentMatrix = function () {
      return OBSOLETE_rentMatrixService.getHasRentMatrix(
        $scope.prospect.property_id
      ).then(function (response) {
        $scope.data.hasRentMatrix = response.data.has_rent_matrix;
      });
    };

    self._getQuotes = function () {
      return quotesService
        .getProspectQuotes($scope.prospect.id)
        .then(function (response) {
          $scope.data.quotes = response.data.quotes || [];
        });
    };

    self._alternativeContactSnakeToCamel = function (alternativeContact) {
      return {
        id: alternativeContact.id,
        firstName: alternativeContact.first_name,
        lastName: alternativeContact.last_name,
        email: alternativeContact.email,
        phone: alternativeContact.phone,
        profileId: alternativeContact.profile_id,
        relationshipToPrimary: alternativeContact.relationship_to_primary
      };
    };

    self._getAlternativeContacts = function () {
      return prospectsApi
        .getProspectAlternativeContacts($scope.prospect.id)
        .success(function (response) {
          $scope.data.alternativeContacts =
            response.alternative_contacts.map(
              self._alternativeContactSnakeToCamel
            ) || [];
        });
    };

    self._postReassignStreamPhoneNumber = function () {
      return relayApi
        .reassignStreamPhoneNumber($scope.prospect.stream_id)
        .then((response) => {
          if (response && response.data.lead.assigned_relay_phone) {
            $mdToast.showSimple('Assigned relay phone!');
            $scope.prospect.assigned_relay_phone =
              response.data.lead.assigned_relay_phone;
          } else $mdToast.showSimple('No unique relay phone number available');
        })
        .catch(() => {
          $mdToast.showSimple('Could not assign relay phone');
        });
    };

    self._getPossibleDuplicates = function () {
      return prospectsApi
        .getProspectDuplicates($scope.prospect.id)
        .then(({ data }) => {
          $scope.mergeOptionsRaw = data.duplicate_prospects;
          $scope.data.mergeOptions = data.duplicate_prospects.map((p) => ({
            id: p.id,
            firstName: p.profile.first_name,
            lastName: p.profile.last_name,
            email: p.profile.email,
            phoneNumber: p.profile.formatted_phone_number,
            source: p.profile.source,
            streamId: p.stream_id
          }));
        })
        .catch((error) => {
          $mdToast.showSimple(error);
        });
    };

    self._getCurrentLeasingTeamCustomFields = function () {
      const currentLeasingTeamId = appDataService.getCurrentLeasingTeamId();

      return teamApi
        .getTeamCustomFieldsData(currentLeasingTeamId)
        .then(({ data }) => {
          // map through each custom field and any default values that are currently not stored should be added to
          // our prospects_stored_custom_fields to be saved

          const customFields = {};
          Object.keys(data.custom_fields).map((key) => {
            customFields[key] = data.custom_fields[key].map((field) => {
              if (
                field.default_value !== null &&
                !$scope.prospect.stored_custom_fields[field.id]
              ) {
                let defaultFieldValue = field.default_value;
                if (field.field_type === 'checkbox') {
                  defaultFieldValue = [field.default_value];
                } else if (field.field_type === 'number') {
                  defaultFieldValue = field.default_value * 1;
                }
                $scope.$broadcast(
                  'setInitialStoredProspectValue',
                  field.id,
                  defaultFieldValue
                );
              }
              return field;
            });
            return customFields[key];
          });
          $scope.customFields = customFields;
        })
        .catch((error) => {
          if (error.status === 404) {
            $scope.customFields = {};
            return;
          }
          $mdToast.showSimple(error.data.message);
        });
    };

    self._initialize();
  };

  ProspectsDetailsController.$inject = [
    '$q',
    '$rootScope',
    '$scope',
    '$mdToast',
    'appDataService',
    'quotesService',
    'userInteractionsService',
    'teamApi',
    'prospectsApi',
    'propertyApi',
    'relayApi',
    'unitApi',
    'OBSOLETE_rentMatrixService',
    'alternativeContactModalFactory',
    'prospectLossReasonsService',
    'visitsService',
    'prospectHistoryService',
    'conversationsService',
    'streamCarouselService',
    'managerCalendarEventsService',
    'newProspectService',
    '$moment',
    '$mdDialog',
    'contactTypes',
    'unsubscribeHelper',
    'userService',
    'ProfileService'
  ];

  app.controller('ProspectsDetailsController', ProspectsDetailsController);
})(window.angular);
